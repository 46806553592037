@font-face {
  font-family: GOTHIC;
  src: url('./../fonts/GOTHIC.TTF');
}

button:focus,button:focus-visible{
  border: none;
  outline: none;
}

/* .custom-scrollbar */

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}
  
.scroll.custom-scrollbar::-webkit-scrollbar{
    width: 3px;
}
  
  /* Track */
  
.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
  /* Handle */
  
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
}
  
  /* Handle on hover */
  
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}