.autocomplete {
    position: relative;
    width: 300px;
}

input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.autocomplete-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 4px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style: none;
    z-index: 9;
    max-height: 156px;
    overflow-y: scroll;
    scrollbar-width: -6px;
    scrollbar-width: thin;
}

.autocomplete-list li {
    padding: 10px;
    cursor: pointer;
}

.autocomplete-list li:hover {
    background-color: #f0f0f0;
}