.textt>li>button {
    text-transform: capitalize !important;
    color: white;
}
.textt>li>button:hover {
    color: white;
    /* background-color: lightgrey; */
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    /* color: orange; */
    font-weight: 600;
}

.nav-item {
    border: 1px solid #fff;
}

.refresh
{
    height: 38px;
    margin-left: 8px;
}

.btn-check:focus+.btn, .btn:focus
{
    box-shadow: 0 0 0 0.25rem transparent;
}

