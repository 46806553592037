:root{
    --theme-orange: #FF7200;
    --theme-gray: #F5F5F5;
}

.day-week-navigator{
    background-color: var(--theme-orange);
    border: 1px solid gray;
    color: white;
}
.day-week-navigator span {
    
    color: white;
    font-weight: bold;
    font-size: 16px;
}
.day-week-navigator .btn{
    color: white;
}

.orange-strip-box{
    text-align: center;
    border: 2px solid;
    border-top: 5px solid var(--theme-orange);
    padding: 10px 10px 5px 10px !important;
    background-color: var(--theme-gray);
}
.orange-strip-box span {
    margin-top: -10px !important;
    font-size: 10px;
}
.filter-menu {
    min-width: 15rem;
}
/* .cust-input select option[value="present"] {
    background-color: green !important;
    font-size: 20px !important;
} */

.card-header .form-select{
    font-size: 10px;
}
.no-height{
    height: auto !important;
}