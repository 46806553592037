.workforce-table{
  width: 100%;
      
}

.workforce-table th {
  background-color: #FF7200;
  color: white;
  padding: 10px;
  font-size: 13px;
  text-align: center;
  border: 1px solid white;
}
.workforce-table td {
  padding: 10px;
  font-size: 13px;
  text-align: center;
  border: 1px solid #FF7200;
}
.workforce-table td:second-child{
  color: #FF7200;
}
.workforce-table td a {
  color: #FF7200;
}

.workforce-table tr .attendance {
min-height: 50px;
}
.subheading {
  background-color: #666666;
  color: white;
  font-size: 15px;
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.greybox {
  text-align: center;
  background-color: #dddddd;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #FF7200;
}

input:focus + .slider {
  box-shadow: 0 0 1px #FF7200;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  border-radius: 50%;
}

.outline-box {
border: 1px solid;
}

.present {
  background-color: green !important;
  text-align: center;
  color: white;
  border: 1px solid white !important;
}

.absent {
  background-color: red !important;
  text-align: center;
  color: white;
  border: 1px solid white !important;
}

.holiday {
  background-color: grey !important;
  text-align: center;
  color: white;
  border: 1px solid white !important;
}

.onleave {
  background-color: yellow !important;
  text-align: center;
  color: black;
  border: 1px solid white !important;
}
.bank-card{
border:1px dashed;

}
strong {
font-weight: bold !important;
color: black;
}

.text-orange {
color: #FF7200
}


.accordion-orange .accordion-button {
background-color: #FF7200;
color: white
}


.detail_content {
background: #F5F5F5;
border-left: 3px solid #FF7200;
padding: 5px 10px;
}

.detail_content  table.book_tbl{
width: 100%;
}

.detail_content table.book_tbl td{
vertical-align: top;
padding: 15px 20px;
width: 30%;
}

.detail_content table.book_tbl td.wd25{
width: 20%;
}

.detail_content table.book_tbl td.actbt{
width: 15%;
padding: 0;
}

.detail_content table.book_tbl span.tbl_tt{
display: block;
margin-right: 0;
color: #727376;
position: relative;
font-weight: 600;
}

.detail_content table.book_tbl span.tbl_tt>i{
position: absolute;
left: -20px;
color: #FF7200;
font-size: 1.3rem;
}

.detail_content table.book_tbl span.tbl_tt>img{
position: absolute;
left: -35px;
top: -2px;
height: 25px;
width: 25px;


}

.detail_content table.book_tbl span.tbl_tt>i.ml30,.detail_content table.book_tbl span.tbl_tt>img.ml30{
left: -30px;


}

.detail_content table.book_tbl span.tbl_txt{
display: block;
margin-right: 0;
color: #A3A3A3;
font-family: GOTHIC;
}

.detail_content table.book_tbl span.tbl_txt_sb{
color: #A3A3A3;
margin-right: 0;
font-weight: 100;
}

.custom-modal-small {
width: 40% !important;
}

.attendance-calender{
/* border: 1px solid; */
border-radius: 5%;
background-color: #FF7200;
box-shadow: 5px 5px 10px #bbbbbb;

}

/* Attendance Calender */
.attendance-calender table {
color: black;
/* font-weight: bold; */
width: 100%;
}

.attendance-calender thead tr{
/* background-color: blue; */
color: black;
font-weight: bold;
text-align: center;
}
.attendance-calender th{
font-size: 10px;
font-weight: bold !important;
text-align: center;
}
.attendance-calender th,.attendance-calender td{
padding-left: 5px;
padding-right: 5px;
padding-top: 10px;
padding-bottom: 10px;
border-radius: 20%;
text-align: center;
}
.attendance-calender .absent-cell {
background-color: red;
color: white !important;
}

.attendance-calender .present-cell {
background-color: green;
color: white !important;
}

.attendance-calender .holiday-cell {
background-color: gray;
color: white !important;
}

.attendance-calender .absent-cell {
  background-color: red;
  color: white !important;
  }
  
  .attendance-calender .present-cell {
  background-color: green;
  color: white !important;
  }
  
  .attendance-calender .onleave-cell {
  background-color: yellow;
  /* color: white !important; */
  }

.btn:focus{
outline: none !important;
}

/*  */

.rejected{
background-color: red !important;
color: black;

}
.approved{
background-color: lightgreen !important;
color: black;
}
.pending{
background-color: yellow !important;
color: black;

}
.box {
width: 300px;
height: 300px;
border: 1px solid #444444;
border-radius: 1%;
}

.left-orange-strip-box{
text-align: center;
border: 1px solid;
border-left: 5px solid var(--theme-orange);
padding: 10px 10px 0px 10px !important;
background-color: var(--theme-gray);
}
.left-orange-strip-box .text {
/* margin-top: -10px !important; */
font-size: 18px;
}
.cust-pagination .page-link{
color: var(--theme-orange);
}
.cust-pagination .page-item.active .page-link{
background-color: var(--theme-orange);
border-color: var(--theme-orange);
}
.form-control{
font-size: 0.8rem;
}
.form-select{
font-size: 0.8rem;
}
.form-control::placeholder{
font-size: 0.8rem;
}
.form-select::placeholder{
font-size: 0.8rem;
}

.error {
font-size: 10px;
}